<template>
  <v-card class="app-card"
    color="#fff"
    elevation="0"
    tile>
      <v-row class="ma-0 flex-nowrap">
        <div class="app-card__btn-move handler" v-if="mode !== 'low'">
          <img src="~@/assets/img/move-icon.svg" alt="" >
        </div>

        <div class="app-card__checkbox ml-4" v-if="mode !== 'low'" >
          <v-checkbox v-model="checkID" 
            @change="$emit('addRemoveID', item.id, listItems)" 
            :value="item.id"
            :key="checkID" >
          </v-checkbox>
        </div>
        

        <div class="pa-4">
          <div class="app-card__img-wrapper mt-1">
            <img :src="item.image" 
              class="app-card__image" 
              alt=""
            >
          </div>
        </div>

        <div class="app-card__content d-flex flex-column py-4 pr-4">
          <div class="app-card__header d-flex"
            :class="title ? 'justify-space-between' : 'justify-end'">
              <h3 v-if="title" class="caption-3">
                <span v-if="item.brandName">
                  {{item.brandName}}<br>
                </span>
                {{ title }}
              </h3>

              <div v-if="mode !== 'low' && status && Object.keys(status).length" class="status">
                <v-tooltip bottom color="#F7F7F7" >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon :color="status.color"
                      v-bind="attrs"
                      v-on="on" >
                        {{ status.icon }}
                    </v-icon>
                  </template>
                    <span class="tooltip__txt">{{ status.txt }}</span>
                  </v-tooltip>
              </div>
          </div>

          <div class="app-card__body mb-1 mt-1">
            <p v-if="((item.content_type === 'product' || item.content_type === 'carousel') && item.article)" 
              class="mb-0 txt">
                Артикул {{ item.article }}
            </p>

            <div v-else class="colors d-flex flex-nowrap">
              <div class="d-flex flex-nowrap align-center">
                <v-icon class="mr-2" v-if="!item.background">
                      mdi-cancel
                  </v-icon>
                <div v-else class="circle mr-2" 
                  :style="`background-color: ${item.background}`">
                </div>

                <p class="mb-0 txt mr-4">Цвет фона</p>
              </div>

              <div class="d-flex flex-nowrap align-center">
                <div class="circle mr-2" 
                  :style="`background-color: ${item.color}`">
                </div>

                <p class="mb-0 txt mr-4">Цвет текста</p>
              </div>
            </div>
          </div>

          <div v-if="item.available_for && item.available_for.length" 
            class="app-card__groups d-flex mb-4">
              <p v-for="(el, i) in item.available_for" :key="i"
                class="mb-0 txt mr-2">
                  {{ el.name }}{{ item.available_for[i+1] ? ',' : '' }}
              </p>
          </div>

          <div class="app-card__footer mt-auto d-flex justify-space-between align-center">
            <div class="date-range">
              <h4 class="caption-4">
                <span v-if="item.start_date">
                  {{ $moment(item.start_date).format('DD.MM.YYYY') }}
                </span>  
                <span v-if="item.end_date">
                  - {{ $moment(item.end_date).format('DD.MM.YYYY') }}
                </span>  
              </h4>
            </div>

            <div class="ctrl-menu">
              <transition name="fade_03">
                <ItemCtrlMenu v-if="mode !== 'low' && !!!listID.length"
                  :isValid="isValid" 
                  :item="item" />
              </transition>
            </div>
          </div>
        </div>
      </v-row>
  </v-card>
</template>

<script>
import ItemCtrlMenu from '@/components/promo/ItemCtrlMenu'
export default {
  name: 'PromoListItem',
  props: {
    listItems: {
      type: Array,
    },
    item: {
      type: Object,
      require: true,
    },
    listID: {
      type: Array,
    },
    mode: {
      type: String
    },
  },
  components: { ItemCtrlMenu },
  data: vm => ({
    checkID: vm.listID && vm.listID.includes(vm.item.id) ? vm.item.id : null,
  }),
  computed: {
    isValid: function() {
      if ( this.$route.name === 'PromoCarousel' ) return true;
      if ( this.item.translates.length && 
        this.item.available_for.length ) 
      {
        return true
      } else {
        return false
      }
    },
    title: function() {
      if ( this.$route.name === 'PromoCarousel' ) return this.item.name;
      if ( this.item.translates.length ) {
        return this.item.translates.find(obj => obj.language.code === 'ru').name
      } else if ( this.item.name ) {
        return this.item.name
      }
    },
    status: function() {
      switch ( true ) {
        case !this.isValid:
          return {
            icon: 'mdi-alert-circle-outline',
            color: '#EB174B',
            name: 'not-valid',
            txt: 'Не валидное',
          }
        case this.item.is_public:
          return {
            icon: 'mdi-check-circle-outline',
            color: '#27A248',
            name: 'prod',
            txt: 'Опубликовано',
          }
        case this.item.is_test && !this.item.is_public:
          return {
            icon: 'mdi-dots-horizontal-circle-outline',
            color: '#818181',
            name: 'test',
            txt: 'Тестируется',
          }
        default:
          return {}
      }
    },
  },
  watch: {
    listID() {
      this.checkID = this.listID.includes(this.item.id) ? this.item.id : null;
    }
  }
}
</script>

<style lang="scss" scoped>
.app-card {
  &__btn-move {
    display: grid;
    place-items: center;
    border-right: 1px solid #dadada;
    width: 30px;
    min-width: 30px;
    cursor: pointer;
  }

  &__checkbox {
    display: grid;
    place-items: center;
  }

  &__img-wrapper {
    position: relative;
    border-radius: 3px;
    height: 75px;
    overflow: hidden;
  }

  &__image {
    width: 75px;
    height: 75px;
    object-fit: cover;
  }

  &__content {
    width: 100%;
  }
}

.caption-3 {
  font-family: 'Source Sans Pro';
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #56565A;
}
.caption-4 {
  font-family: 'Source Sans Pro';
  font-weight: 700;
  font-size: 14px;
  line-height: 19px;
  color: #56565A;
}
.txt {
  font-family: 'Source Sans Pro';
  font-size: 14px;
  line-height: 19px;
  color: #56565A;
}
.circle {
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 1px solid #dadada;
}

.tooltip__txt {
  color: #56565A;
}
</style>
